//@ts-check
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  // const sectionHeader = {
  //   title: 'Workflow that just works',
  //   paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                  Controla desde cualquier lugar
                </h3>
                <p className="m-0">
                  Programa la fecha y hora de tu contenido. Haz cambios en el momento que quieras desde nuestra aplicación.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <img
                  src={require('../assets/images/landing-section-1.svg')}
                  alt="Digital Signage Software"
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content reveal-from-right" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                  Crea y publica contenido en segundos
                  </h3>
                <p className="m-0">
                  Sube imágenes, videos, presentaciones o sincroniza tus aplicaciones favoritas. Solo arrastra tus archivos a nuestra aplicación y ScreenFox hará el resto.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <img
                  src={require('../assets/images/landing-section-2.svg')}
                  alt="Playlists"
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div> */}
                <h3 className="mt-0 mb-12">
                  Instalación en minutos
                </h3>
                <p className="m-0">
                  No necesitas conocimiento técnico. Conecta nuestro hardware a tu pantalla, sigue las instrucciones y comienza a reproducir tu contenido.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <img
                  src={require('../assets/images/media-player-alternate.svg')}
                  alt="Media Player"
                  width={10}
                  height={200} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;