//@ts-check
import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import FeaturesTiles from '../components/FeaturesTiles'
import FeaturesSplit from '../components/FeaturesSplit'
import Cta from '../components/Cta'
import LogoTiles from '../components/LogoTiles'

import favicon from '../assets/images/favicon.ico'
import { drift, driftConfig, tagManager, bodyTagManager } from '../scripts'

class RootIndex extends React.Component {
  render() {
    const siteTitle = "Digital Signage Software para cualquier pantalla | ScreenFox";
    const siteDescription = "ScreenFox es una aplicación de software de digital signage para cualquier pantalla. Muestra el contenido que sea importante para tu empresa – ya sea noticias, promociones, redes sociales, o hasta empleados del mes";

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle}>
            <html lang="es" />
            <meta name="description" content={siteDescription} />
            <link rel="icon" href={favicon} type="image/x-icon" />

            <link rel="alternate" href={`https://getscreenfox.com${this.props.location.pathname}`} hrefLang="es" />

            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={siteDescription} />

            {/* TODO: ADD NEW IMAGE HERE */}
            {/* <meta property="og:image" content="new Image" /> */}

            <meta property="og:url" content="https://getscreenfox.com/" />

            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-970266593"></script>

            <script>{drift}</script>
            <script>{driftConfig}</script>
            <script>{tagManager}</script>
          </Helmet>

          <div
            dangerouslySetInnerHTML={{
              __html: bodyTagManager,
            }}></div>


          <Hero className="illustration-section-01" />

          <LogoTiles bottomDivider={true} />

          <FeaturesTiles pushLeft />

          <FeaturesSplit topDivider imageFill />

          <Cta topDivider split />

        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
query HomeQuery {
  allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
    edges {
      node {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
           ...GatsbyContentfulSizes_withWebp
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  allContentfulPerson(filter: { id: { eq: "c15jwOBqpxqSAOy2eOO4S0m" } }) {
    edges {
      node {
        name
        shortBio {
          shortBio
        }
        title
        heroImage: image {
          sizes(
            maxWidth: 1180
            maxHeight: 480
            resizingBehavior: PAD
            background: "rgb:000000"
          ) {
            ...GatsbyContentfulSizes_withWebp
          }
        }
      }
    }
  }
}
`
