//@ts-check
import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';
import ButtonGroup from './elements/ButtonGroup';
import Button from './elements/Button';
import Modal from './elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} style={{paddingBottom: "0"}}>
          <div className="hero-content">
            <div className="hero-text">
              
              <div className="container-xs">
              <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                Controla tus pantallas de Digital Signage
            </h1>
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  ScreenFox es una aplicación de software de digital signage que te ayuda a crear y controlar tu contenido en una, o miles de pantallas. Desde cualquier lugar. En tiempo real.
                </p>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  <ButtonGroup>
                    <Button tag="a" color="primary" wideMobile href="/demo">
                      Prueba Gratis
                    </Button>
                    <Button tag="a" color="secondary" wideMobile onClick={openModal}>
                      Ver Video
                  </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>


            <div className="hero-figure reveal-from-bottom illustration-element-01 container-xs" data-reveal-value="20px" data-reveal-delay="800">

              <img
                className="has-shadow"
                src={require('../assets/images/pantalla01.png')}
                alt="Pantalla Digital Signage"
                style={{maxWidth: "530px", width: "100%"}}
                />

            </div>

          </div>

          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/285132817"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;